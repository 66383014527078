import { loadableReady } from '@loadable/component';
import PropTypes from 'prop-types';
import React from 'react';
import { hydrate } from 'react-dom';
import { Router as MemoryRouter } from 'react-router-dom';

import DebugRouter from '@core/utils/DebugRouter';

import history from './history';

import DashApp from '.';

const DashRouter = props => {
  return 'ngSyncState' in window ? (
    <MemoryRouter history={history}>
      <DashApp {...props} />
    </MemoryRouter>
  ) : (
    <DebugRouter>
      <DashApp {...props} />
    </DebugRouter>
  );
};

DashRouter.propTypes = {
  baseUrl: PropTypes.string,
};

const initialize = () => {
  loadableReady(() => {
    const root = document.getElementById('dashReact');
    const props = $('#dashReact-props').data('initial-props') || {};
    if (root) {
      hydrate(<DashRouter {...props} />, root);
      document.body.classList.add('body-is-loaded');
    }
  });
};

const holdInitialization = setInterval(() => {
  if (typeof $ !== 'undefined') {
    clearInterval(holdInitialization);
    initialize();
  }
}, 20);
